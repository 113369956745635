import {useEffect} from "react";
import * as VKID from '@vkid/sdk';
import {readLocal} from "../utils";
import "../../assets/styles/VKLoading.scss"
//@ts-ignore
import Lottie from 'react-lottie';
import animationData from '../../assets/loading_animation.json';

const VKLoading = () => {
    const authorizeBackend = () => {
        const state = readLocal("vk-state") ?? undefined;

        // get code and device id from  url params
        VKID.Config.init({
            app: 52258631,
            redirectUrl: 'https://mindon.etterellar.ru/vk-auth/',
            // redirectUrl: 'http://localhost',
            scope: 'email',
            state: state
        });
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const deviceId = urlParams.get('device_id');
        if (code && deviceId) {
            console.log("Code and device id received");
            // send code and device id to backend
            VKID.Auth.exchangeCode(code, deviceId).then(
                (response) => {
                    console.log("Response: ", response);
                    fetch("https://account.mindon.space/api/user/auth/convert-token/", {
                        method: 'POST',
                        headers: {
                            "Content-type": "application/json"
                        },
                        body: JSON.stringify({
                            grant_type: 'convert_token',
                            client_id: 'ObSzbugqa1bbpO80ReYWRSPPD39w44wld37On61v',
                            token: response.access_token,
                            backend: 'vk-oauth2',
                            device: 'web',
                            vk_client_id: 52258631
                        })

                    }).then((response: any) => console.log(response)).catch((error: any) => console.log(error))

                    // window.location.href = "/";
                }
            ).catch(
                (error) => {
                    console.log("Error: ", error);
                    // window.location.href = "/log_in";
                }
            );
        } else {
            console.log("Code and device id not received");
            // window.location.href = "/log_in";
        }
    }
    useEffect(() => authorizeBackend(), []);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className="vk-loading">
            <div className="vk-loading__spinner">
                <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                />
            </div>
            <div className={"vk-loading__title"}>Авторизация...</div>
        </div>
    );
};

export default VKLoading;