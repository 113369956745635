import Collapsible from "../global/Collapsible";
import React, {forwardRef} from "react";

const Faq = forwardRef<HTMLDivElement>((props, ref) => {
    return <div className={"faq"} ref={ref}>
        <div className={"faq-title involve-500"}>
            FAQ
        </div>
        <div className={"questions"}>
            <Collapsible title={"Кому задавать вопросы во время курса?"} children={<div>
                <p>Lorem ipsum dolor sit amet sollicitudin odio sed at ultrices tristique. Sed ac gravida aliqua
                    adipiscing
                    libero nulla nibh nullam urna nisl elit. Pellentesque pretium euismod cras molestie suspendisse in
                    sollicitudin senectus gravida iaculis ut elit. Est dictumst condimentum ornare consequat nunc congue
                    tincidunt posuere lacinia nisi diam cras elementum. Nulla mattis fames quisque risus aliqua id
                    sodales
                    odio hendrerit.</p>

                <p>Sed fames tempor lectus ultrices fringilla vestibulum neque. Eu porta scelerisque risus vulputate
                    proin
                    condimentum integer venenatis. Nullam porttitor habitasse semper lectus elementum vivamus pretium
                    mattis
                    iaculis nec. Volutpat tristique platea mollis cursus orci sollicitudin feugiat eleifend tempor
                    suspendisse iaculis. Consequat phasellus consectetur neque lacus magna et vulputate.</p>

                <p>Senectus gravida elementum et auctor maecenas mi pharetra leo mi sodales feugiat semper volutpat.
                    Fusce
                    vestibulum semper luctus dui volutpat turpis dapibus et odio pharetra iaculis egestas. Non venenatis
                    semper quam augue phasellus ac tortor ullamcorper mollis porta arcu. Faucibus gravida vestibulum non
                    fermentum convallis adipiscing enim mauris porttitor gravida faucibus proin. Proin ullamcorper donec
                    adipiscing aliquam fames cras iaculis dui sed fringilla gravida.</p>

                <p>Pretium convallis lacinia porttitor adipiscing elementum senectus morbi lobortis consequat
                    integer mattis massa. Tellus iaculis fermentum tincidunt tortor enim sollicitudin maecenas turpis
                    vitae
                    morbi senectus semper dolore. Habitasse velit quisque faucibus luctus etiam aliqua eget ut fusce
                    convallis sed fames. Duis mattis eleifend mattis mauris suspendisse proin habitasse platea ultricies
                    sagittis
                    nibh porta habitasse. At dui porttitor eu praesent quis erat non.</p>
            </div>} showArrow={true}></Collapsible>
            <Collapsible title={"Кому задавать вопросы во время курса?"}
                         children={<div>
                             <p>Lorem ipsum dolor sit amet sollicitudin odio sed
                                 at
                                 ultrices tristique. Sed ac gravida aliqua
                                 adipiscing libero nulla nibh nullam urna nisl
                                 elit.
                                 Pellentesque pretium euismod cras molestie
                                 suspendisse in sollicitudin senectus gravida
                                 iaculis ut elit. Est dictumst condimentum ornare
                                 consequat nunc congue tincidunt posuere lacinia
                                 nisi diam cras elementum. Nulla mattis fames
                                 quisque risus aliqua id sodales odio hendrerit.
                             </p>
                             <p>Sed fames tempor lectus ultrices fringilla
                                 vestibulum neque. Eu porta scelerisque risus
                                 vulputate proin condimentum integer venenatis.
                                 Nullam porttitor habitasse semper lectus elementum
                                 vivamus pretium mattis iaculis nec. Volutpat
                                 tristique platea mollis cursus orci sollicitudin
                                 feugiat eleifend tempor suspendisse iaculis.
                                 Consequat phasellus consectetur neque lacus magna
                                 et vulputate.</p>

                             <p>Senectus gravida elementum et auctor maecenas mi
                                 pharetra leo mi sodales feugiat semper volutpat.
                                 Fusce vestibulum semper luctus dui volutpat turpis
                                 dapibus et odio pharetra iaculis egestas. Non
                                 venenatis semper quam augue phasellus ac tortor
                                 ullamcorper mollis porta arcu. Faucibus gravida
                                 vestibulum non fermentum convallis adipiscing enim
                                 mauris porttitor gravida faucibus proin. Proin
                                 ullamcorper donec adipiscing aliquam fames cras
                                 iaculis dui sed fringilla gravida.</p>

                             <p>Pretium convallis lacinia porttitor adipiscing
                                 elementum senectus morbi lobortis consequat integer
                                 mattis massa. Tellus iaculis fermentum tincidunt
                                 tortor enim sollicitudin maecenas turpis vitae
                                 morbi senectus semper dolore. Habitasse velit
                                 quisque faucibus luctus etiam aliqua eget ut fusce
                                 convallis sed fames. Duis mattis eleifend mattis
                                 mauris suspendisse proin habitasse platea ultricies
                                 sagittis nibh porta habitasse. At dui porttitor eu
                                 praesent quis erat non.</p>
                         </div>} showArrow={true}></Collapsible> <Collapsible
            title={"Кому задавать вопросы во время курса?"} children={<div>
            <p>Lorem ipsum dolor sit amet sollicitudin odio sed at ultrices tristique. Sed ac gravida aliqua adipiscing
                libero nulla nibh nullam urna nisl elit. Pellentesque pretium euismod cras molestie suspendisse in
                sollicitudin senectus gravida iaculis ut elit. Est dictumst condimentum ornare consequat nunc congue
                tincidunt posuere lacinia nisi diam cras elementum. Nulla mattis fames quisque risus aliqua id sodales
                odio
                hendrerit.</p>

            <p>Sed fames tempor lectus ultrices fringilla vestibulum neque. Eu porta scelerisque risus vulputate proin
                condimentum integer venenatis. Nullam porttitor habitasse semper lectus elementum vivamus pretium mattis
                iaculis nec. Volutpat tristique platea mollis cursus orci sollicitudin feugiat eleifend tempor
                suspendisse
                iaculis. Consequat phasellus consectetur neque lacus magna et vulputate.</p>

            <p>Senectus gravida elementum et auctor maecenas mi pharetra leo mi sodales feugiat semper volutpat. Fusce
                vestibulum semper luctus dui volutpat turpis dapibus et odio pharetra iaculis egestas. Non venenatis
                semper
                quam augue phasellus ac tortor ullamcorper mollis porta arcu. Faucibus gravida vestibulum non fermentum
                convallis adipiscing enim mauris porttitor gravida faucibus proin. Proin ullamcorper donec adipiscing
                aliquam fames cras iaculis dui sed fringilla gravida.</p>

            <p>Pretium convallis lacinia porttitor adipiscing elementum senectus morbi lobortis consequat integer mattis
                massa. Tellus iaculis fermentum tincidunt tortor enim sollicitudin maecenas turpis vitae morbi senectus
                semper dolore. Habitasse velit quisque faucibus luctus etiam aliqua eget ut fusce convallis sed fames.
                Duis
                mattis eleifend mattis mauris suspendisse proin habitasse platea ultricies sagittis nibh porta
                habitasse. At
                dui porttitor eu praesent quis erat non.</p>
        </div>} showArrow={true}></Collapsible>
        </div>
    </div>
});
export default Faq;