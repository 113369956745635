import React, {useEffect, useRef} from 'react';
import hex from "../../assets/images/hex.svg";
import "../../assets/styles/Home.scss";

import ReviewCarousel from "./ReviewCarousel";
import FirstScreen from "./FirstScreen";
import Directions from "./Directions";
import LazyLoad from "react-lazyload";
import Faq from "./Faq";
import AskForm from "./AskForm";
import mephi_logo from "../../assets/images/mephi.svg";
import logo from "../../assets/images/logo.svg";
import {Link} from "react-router-dom";
import tgIcon from "../../assets/images/telegram.svg";
import vkIcon from "../../assets/images/vk.svg";
import {scrollPage} from "../utils";
import Tariffs from "./Tariffs";

const Home = () => {

    const scienceRef = useRef(null);
    const feedbackRef = useRef(null);
    const aboutRef = useRef(null);
    const tariffRef = useRef(null);

    const getAppLinks = () => {
        return (<div className={"app-links"}>
            <Link to="/" onClick={() => scrollPage(scienceRef)}>Предметы</Link>
            <Link to="/" onClick={() => scrollPage(feedbackRef)}>Отзывы</Link>
            <Link to="/" onClick={() => scrollPage(aboutRef)}>О платформе</Link>
            <Link to="/" onClick={() => scrollPage(tariffRef)}>Тарифы</Link>
        </div>);
    }
    return (
        <div className={"home-page"}>
            <nav className={"navigation"}>
                <div className={"main-row"}>
                    <div className={"logos"}>
                        <img src={mephi_logo}/>
                        <img src={logo}/>
                    </div>
                    {getAppLinks()}
                    <a href={"/log_in"} className={"log-in-btn"}>Войти</a>
                </div>

            </nav>
            <img src={hex} alt={"ЕГЭ"} className={"hex-decoration"}/>
            <FirstScreen directions={scienceRef} askQuestion={aboutRef}/>
            <LazyLoad className={"w-full"}>
                <Directions ref={scienceRef}/>
                <ReviewCarousel ref={feedbackRef}/>
                <Tariffs ref={tariffRef}/>
                <Faq ref={aboutRef}/>
                <AskForm/>
                <footer>
                    <div className="footer-content">
                        <div className="logo-container">
                            <img src={mephi_logo} alt="Logo 1" className="logo"/>
                            <img src={logo} alt="Logo 2" className="logo"/>
                        </div>
                        {getAppLinks()}
                        <div className={"footer-links"}>
                            <div className="email">
                                Почта <a href="mailto:mindon.rus@gmail.com"
                                         className={"main-text underlined"}>mindon.rus@gmail.com</a>
                            </div>
                            <div className="social-icons">
                                <a href="https://t.me/your-channel" className="social-icon"><img src={tgIcon}
                                                                                                 alt="Telegram Icon"/></a>
                                <a href="https://vk.com/your-page" className="social-icon"><img src={vkIcon}
                                                                                                alt="VK Icon"/></a>
                            </div>
                        </div>
                        <div className="legal">
                            <p>&copy; 2024</p>
                            <div className={"legal-links"}>
                                <a href="#">Политика конфиденциальности</a>
                                <a href="#">Пользовательское соглашение</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </LazyLoad>

        </div>);
};

export default Home;