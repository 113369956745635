import React, {Suspense} from 'react';
import './assets/styles/App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./components/home/Home";
import logo from "./assets/images/logo.svg";
import mephi_logo from "./assets/images/mephi.svg";
import tgIcon from "./assets/images/telegram.svg";
import vkIcon from "./assets/images/vk.svg";
import LogIn, {Register} from "./components/auth/LogIn";
import Account from "./components/account/Account";
import VKLoading from "./components/auth/VKLoading";

function App() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/log_in" element={<LogIn/>}/>
                    <Route path="/vk-auth" element={<VKLoading/>}/>
                    <Route path="/sign_up" element={<Register/>}/>
                    <Route path="/account" element={<Account/>}/>
                </Routes>
            </Suspense>

        </Router>
    );
}

export default App;
