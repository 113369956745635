import React from 'react';
import '../../assets/styles/Account/Account.scss';

const GoalProgress: React.FC = () => {
    const days = [
        { day: 'Пн', percentage: 100 },
        { day: 'Вт', percentage: 40 },
        { day: 'Ср', percentage: 80 },
        { day: 'Чт', percentage: 100 },
        { day: 'Пт', percentage: 30 },
        { day: 'Сб', percentage: 10 },
        { day: 'Вс', percentage: 0 }
    ];

    const goalAchievedDays = days.filter(day => day.percentage >= 50).length;

    return (
        <div className="goal-progress">
            <div className="goal-header">
                <h3>Моя цель</h3>
                <span className="settings-icon">⚙️</span>
            </div>
            <div className="goal-bars">
                {days.map((day, index) => (
                    <div key={index} className="goal-bar">
                        <div
                            className="bar"
                            style={{ height: `${day.percentage}%`, backgroundColor: day.percentage >= 50 ? '#4CAF50' : '#FFC107' }}
                        >
                            {day.percentage > 0 && <div className="bar-percentage">{day.percentage}%</div>}
                        </div>
                        <span className="day-label">{day.day}</span>
                    </div>
                ))}
            </div>
            <div className="goal-summary">
                <p>Вы достигали цели:</p>
                <p className="goal-days">{goalAchievedDays}/7 дней</p>
            </div>
        </div>
    );
};

export default GoalProgress;