const AskForm = () => {
    return <div className={"ask-form"}>
        <div className={"ask-form-layout"}>
            <div className={"text"}>
                <div className={"title involve-500"}>Остались вопросы? Мы поможем!</div>
                <div className={"description"}>Запишитесь на бесплатную консультацию — мы ответим на любой вопрос и
                    подскажем, как действовать, чтобы получить на ЕГЭ 80+ и поступить на бюджет
                </div>
            </div>
            <form className={"form"}>
                <input type={"text"} placeholder={"Имя"} style={{width: "100%"}} required={true}/>
                <input type={"phone"} placeholder={"Телефон"} required={true}/>
                <input type={"email"} placeholder={"Email"} required={true}/>
                <div className={"checkbox-group"}>
                    <input type={"checkbox"} placeholder={"Ваш вопрос"} required={true}/>
                    <p>Принимаю условия соглашения и политики конфиденциальности</p>
                </div>
                <button className={"primary-button"}>Получить консультацию</button>
            </form>
        </div>
    </div>
}

export default AskForm;