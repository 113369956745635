import React, {ReactNode} from 'react';

import Logo from "../../assets/images/logo.svg";
import MephiLogo from "../../assets/images/mephi.svg";
import GooglePlay from "../../assets/images/googleplay.png";
import Appstore from "../../assets/images/appstore.png";
import Hex from "../../assets/images/hex.svg";
import "../../assets/styles/Auth.scss";


interface CommonauthProps {
    children: ReactNode;
}

const AuthLayout: React.FC<CommonauthProps> = ({children}) => {
    return (
        <div className={"auth"}>
            <div className="auth-header">
                <div className="auth-logo">
                    <img src={MephiLogo} alt={"MEPHI"}/>
                    <img src={Logo} alt={"MindOn"}/>
                </div>
                <img src={Hex} className={"hex-left"}/>
                <div className="auth-title involve-500">
                    Сдай <span className={"main-text"}>ОГЭ и ЕГЭ</span> на отлично с нашим приложением!
                </div>
                <img src={Hex} className={"hex-right"}/>
                <div className={"stores"}>
                    <a href={"#"}>
                        <img src={GooglePlay} alt={"Google play"}/>
                    </a>
                    <a href={"#"}>
                        <img src={Appstore} alt={"AppStore"}/>
                    </a>
                </div>
            </div>

            <main className="auth-main">
                {children}
            </main>
            <div className={"stores"}>
                <a href={"#"}>
                    <img src={GooglePlay} alt={"Google play"}/>
                </a>
                <a href={"#"}>
                    <img src={Appstore} alt={"AppStore"}/>
                </a>
            </div>

        </div>
    );
}

export default AuthLayout;