import ToolsPic from "../../assets/images/tools.png";
import React from "react";
import {scrollPage} from "../utils";

interface FirstScreenProps {
    directions: React.MutableRefObject<any>;
    askQuestion: React.MutableRefObject<any>;
}

const FirstScreen = (props: FirstScreenProps) => {
    return <div className={"title"}>
        <div className={"main-info "}>
            <div className={"small-text involve-500"}>Эффективная подготовка к экзаменам вместе с Mind ON</div>
            <div className={"big-text involve-500"}>Сдай <span className={"main-text"}>ОГЭ и ЕГЭ</span><br
                className={"md-hidden"}/> на отлично<br className={"xl-visible"}/> с<br
                className={"md-visible xl-hidden"}/> нашим
                приложением!
            </div>
            <div className={"buttons"}>
                <a href={"/"} className={"primary-button"} onClick={(e) => { e.preventDefault(); scrollPage(props.directions)}}>Выбрать курс</a>
                <a href={"/"} className={"secondary-button"} onClick={(e) => { e.preventDefault(); scrollPage(props.askQuestion)}}>Записаться на консультацию</a>
            </div>
            <div className={"stats"}>
                <div className={"stats-row"}>
                    <div className={"stats-number"}>500+</div>
                    <div className={"stats-text"}>учеников обучаются<br/>у нас сейчас
                    </div>
                </div>
                <div className={"stats-row"}>
                    <div className={"stats-number"}>1000</div>
                    <div className={"stats-text"}>отзывов довольных<br/>учеников</div>
                </div>
            </div>

        </div>
        <img src={ToolsPic}/>
        {/*<img src={toolsPic} alt={"Канцелярские принадлежности"}/>*/}
    </div>
}

export default FirstScreen;