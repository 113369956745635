import {setAuthToken, setRefreshToken, UnauthenticatedRedirect} from "../utils";
export const LogInRequest = async (login: string, password: string, registration: boolean = false): Promise<boolean> => {
    let body = {
        grant_type: 'password',
        client_id: 'KQ2zKhjypAaLVGYvxzzzNiumwDBPZJaumddmIe4Y',
        username: login, // email
        password: password,
        registration: registration,
        device: 'web',
    }
    const url = "https://account.mindon.space/api/user/auth/token/";
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        redirect: "manual"
    });

    UnauthenticatedRedirect(response);
    if (response.status === 200) {
        const data = await response.json();
        setAuthToken(data.access_token);
        setRefreshToken(data.refresh_token);
        return true;
    } else{
        return false;
    }

}