import React, { ReactNode, useState, useRef, useEffect } from "react";

interface CollapsibleProps {
    title: string;
    children: ReactNode;
    showArrow?: boolean;
}

const Collapsible: React.FC<CollapsibleProps> = ({ title, children, showArrow = false }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    const handleToggle = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.scrollHeight);
        }
    }, [isExpanded]);

    const classes = `collapsible-card ${isExpanded ? "is-expanded" : ""}`;
    const currentHeight = isExpanded ? height : 0;

    return (
        <div className={classes} onClick={handleToggle}>
            <div className="card-title">
                <h2>{title}</h2>
                {showArrow && <span className="arrow">{isExpanded ? "–" : "+"}</span>}
            </div>
            <div className="card-collapse" style={{ height: currentHeight + "px" }}>
                <div className="card-body" ref={ref}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Collapsible;