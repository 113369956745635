import React from 'react';
import chevronLeft from '../../assets/images/chevron_left.svg';
import chevronRight from '../../assets/images/chevron_right.svg';

interface CustomArrowProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    type: 'prev' | 'next';
}

function CustomArrow(props: any ){
    const { className, style, onClick, type } = props;
    return  (
        <button
            className={className}
            style={{...style, display: 'block'}}
            onClick={onClick}
        >
            <img src={type === 'prev' ? chevronLeft : chevronRight} alt={type === 'prev' ? 'prev' : 'next'}/>
        </button>
    )
};

export default CustomArrow;