import React, {forwardRef, useEffect} from "react";
import physics from "../../assets/images/physics.png";
import math from "../../assets/images/math.png";

interface TariffsProps {
    title: React.ReactNode;
    bothDirections: boolean;
    prices?: Price[];
}


const TariffCard = (props: TariffsProps) => {
    //find price object by months field
    const getPrice = (months: number) => {
        if (props.prices === null) {
            return 0;
        }
        const price = props.prices?.find((price) => price.months === months);
        return price ? price.price : 0;
    }

    const formatPrice : (number: number) => string = (number) => {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true
        }).format(number).replace(/,/g, ' ');
    }

    return <div className={"tariff-card"}>
        <div className={"tariff-card-header"}>
            {props.title}
        </div>
        <div className={"tariff-directions"}>
            <div className={"tariff-directions-one"}>
                <img src={physics} alt={"Физика"}/>
                <h4>Физика</h4>
            </div>
            <div className={"tariff-directions-text"}>{props.bothDirections ? "И" : "ИЛИ"}</div>
            <div className={"tariff-directions-one"}>
                <img src={math} alt={"Математика"}/>
                <h4>Математика</h4>
            </div>
        </div>
        <div className={"tariff-card-price"}>
            <div className={"tariff-card-price-text"}>
                {`${formatPrice(getPrice(1))} руб – 1 мес`}
            </div>
            <div className={"tariff-card-price-text"}>
                {`${formatPrice(getPrice(3))} руб – 3 мес`}
            </div>
            <div className={"tariff-card-price-text"}>
                {`${formatPrice(getPrice(12))} руб – 1 год`}
            </div>

        </div>
    </div>
}

export interface TariffsData {
    premium: SubType
    pro: SubType
}

export interface SubType {
    "1": Price[]
    "2": Price[]
}

export interface Price {
    months: number
    price: number
}

const Tariffs = forwardRef<HTMLDivElement>((props, ref) => {
    const [prices, setPrices] = React.useState<TariffsData | null>(null);
    const [isPremium, setIsPremium] = React.useState(true);
    const loadPrices = () => {
        fetch("https://account.mindon.space/api/services/subscriptions/prices/all/")
            .then(response => response.json()
                .then(data => setPrices(data)));
    }

    useEffect(() => loadPrices(), []);


    return (
        <div className={"tariffs"} ref={ref}>
            <div className={"tariffs__header"}>
                <div className={"tariffs__header-title involve-500"}>Тарифы</div>
                <div className={"tariffs__header-buttons"}>
                    <button className={`tariffs__header-button ${isPremium ? "active" : ''}`} onClick={() => setIsPremium(true)}>Премиум</button>
                    <button className={`tariffs__header-button ${!isPremium ? "active" : ''}` } onClick={() => setIsPremium(false)}>ПРО</button>
                </div>
            </div>
            <div className={"tariffs__content"}>
                <div className={"tariffs-description"}>
                    <div className={"tariffs-description-title involve-500"}>При покупке <span
                        className={"main-text"}>{isPremium ? 'премиум' : "ПРО"}</span> тарифа доступны:
                    </div>
                    <ul>
                        <li>вся практика и тесты</li>
                        <li>все тексты теории</li>
                        <li>карточки и разделы</li>
                        <li>ответы и решения к задачам</li>
                    </ul>
                </div>
                <div className={"tariffs-list"}>
                    <TariffCard bothDirections={false} prices={isPremium ? prices?.premium["1"] : prices?.pro["1"]}
                                title={<div className={"involve-500"}>Для <span
                                    className={"main-text"}>одного</span> любого приложения:</div>}/>
                    <TariffCard bothDirections={true} prices={isPremium ? prices?.premium["2"] : prices?.pro["2"]}
                                title={<div className={"involve-500"}>Для <span
                                    className={"main-text"}>двух</span> приложений сразу:</div>}/>
                </div>
            </div>
        </div>
    );
});

export default Tariffs;