// ReadLocal allows to get string by key from localstorage
import {useNavigate, useNavigation} from "react-router-dom";

const readLocal = (key: string): string | null => {
    return localStorage.getItem(key);
}

// WriteLocal allows to write string by key to localstorage
const writeLocal = (key: string, value: string | null): void => {
    if (value === null) {
        localStorage.removeItem(key);
        return
    }
    localStorage.setItem(key, value);
}

export const getAuthToken = (): string | null => {
    return readLocal("auth_token");
}

export const setAuthToken = (token: string | null): void => {
    writeLocal("auth_token", token);
}
export const getRefreshToken = (): string | null => {
    return readLocal("refresh_token");
}

export const setRefreshToken = (token: string): void => {
    writeLocal("refresh_token", token);
}


export {readLocal, writeLocal};

export const scrollPage = (ref: any) => {
    console.log(ref)
    console.log(ref.current)
    ref.current?.scrollIntoView({behavior: "smooth"});

    if (ref.current) {
        const topPos = ref.current.getBoundingClientRect().top + window.scrollY;
        console.log(topPos);
    //     window.scrollTo({ top: topPos, behavior: 'smooth' });
    }
}

export const getWeekDay = (date: Date): number => {
    const numberdayweek = [6,0,1,2,3,4,5];
    return numberdayweek[date.getDay()];
}

export const generateRandomString: (length: number) => string = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

// UnauthenticatedRedirect is a component that tries to get new access token using refresh token of redirects to /log_in if user is not authenticated
export const UnauthenticatedRedirect = (response: Response) => {
    if (response.status === 401){
        const refreshToken = getRefreshToken();
        // navigate("/log_in");
        return false;
        // if (refreshToken === null) {
        //     navigate("/log_in");
        //     return null;
        // }
        // const refresh = async () => {
        //     const response = await fetch("https://account.mindon.space/api/user/auth/token", {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({
        //             grant_type: 'refresh_token',
        //             client_id: 'KQ2zKhjypAaLVGYvxzzzNiumwDBPZJaumddmIe4Y',
        //             refresh_token: refreshToken,
        //             device: 'web',
        //         })
        //     });
        //     if (response.status === 200) {
        //         const data = await response.json();
        //         setAuthToken(data.access_token);
        //         setRefreshToken(data.refresh_token);
        //     } else {
        //         history.push("/log_in");
        //     }
        // }
        // refresh();
    }
    return true;
}