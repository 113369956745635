import React, { useState, useEffect } from 'react';
import '../../assets/styles/Account/Calendar.scss';

interface Day {
    date: number;
    isCurrentMonth: boolean;
}

const Calendar: React.FC = () => {
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [daysInMonth, setDaysInMonth] = useState<Day[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const months = [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ];

    useEffect(() => {
        generateCalendar(currentDate);
    }, [currentDate]);

    const generateCalendar = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDayOfMonth = getWeekDay(new Date(year, month, 1));
        const lastDateOfMonth = new Date(year, month + 1, 0).getDate();
        const prevMonthLastDate = new Date(year, month, 0).getDate();

        const daysArray: Day[] = [];
        for (let i = firstDayOfMonth; i > 0; i--) {
            daysArray.push({ date: prevMonthLastDate - i + 1, isCurrentMonth: false });
        }

        for (let i = 1; i <= lastDateOfMonth; i++) {
            daysArray.push({ date: i, isCurrentMonth: true });
        }

        const remainingDays = 42 - daysArray.length;
        for (let i = 1; i <= remainingDays; i++) {
            daysArray.push({ date: i, isCurrentMonth: false });
        }

        setDaysInMonth(daysArray);
    };

    const getWeekDay = (date: Date) => {
        let day = date.getDay();
        return day === 0 ? 6 : day - 1; // Adjust for the week starting on Monday
    };

    const handleMonthChange = (monthIndex: number) => {
        const newDate = new Date(currentDate.getFullYear(), monthIndex, 1);
        setCurrentDate(newDate);
        setIsDropdownOpen(false);
    };

    const handleYearChange = (year: number) => {
        const newDate = new Date(year, currentDate.getMonth(), 1);
        setCurrentDate(newDate);
        setIsDropdownOpen(false);
    };

    const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

    const activityDates: Record<number, 'light' | 'medium' | 'dark' | undefined> = {
        7: 'light',
        10: 'light',
        13: 'medium',
        14: 'medium',
        16: 'medium',
        17: 'medium',
        20: 'medium',
        21: 'medium',
        23: 'dark',
        28: 'light',
        30: 'medium'
    };

    return (
        <div className="calendar">
            <div className="calendar-header">
                <h3>Моя активность</h3>
                <div className="calendar-dropdown">
                    <span onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        {months[currentDate.getMonth()]} {currentDate.getFullYear()}
                    </span>
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            <div className="month-select">
                                {months.map((month, index) => (
                                    <div
                                        key={index}
                                        className="dropdown-item"
                                        onClick={() => handleMonthChange(index)}
                                    >
                                        {month}
                                    </div>
                                ))}
                            </div>
                            <div className="year-select">
                                {[...Array(10)].map((_, index) => {
                                    const year = currentDate.getFullYear() - 5 + index;
                                    return (
                                        <div
                                            key={year}
                                            className="dropdown-item"
                                            onClick={() => handleYearChange(year)}
                                        >
                                            {year}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="calendar-grid">
                {daysOfWeek.map(day => (
                    <div className="calendar-day" key={day}>{day}</div>
                ))}
                {daysInMonth.map((day, index) => (
                    <div
                        key={index}
                        className={`calendar-date ${day.isCurrentMonth ? activityDates[day.date] || '' : 'disabled'}`}
                    >
                        {day.date}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Calendar;