import Slider from 'react-slick';
import {forwardRef, useEffect, useRef, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import oleg from "../../assets/images/oleg.png";
import mariya from "../../assets/images/maria.png";
import CustomSliderButton from "./CustomsliderButton";
import star from "../../assets/images/star.svg";

const reviews = [
    {
        name: "Олег",
        grade: "9 класс",
        review: "спасибо разработчикам за это приложение! реально очень крутое, здесь есть как теоретическая часть, так и практическая в виде тестов, что очень хорошо, так как после теории вы можете закрепить полученную информацию тестом",
        rating: 5,
        avatar: oleg
    },
    {
        name: "Мария",
        grade: "11 класс",
        review: "Обычно не пишу комментарии, но этому приложению не могла не написать свой восторженный комментарий. Спасибо большое создателям этого чудесного приложения! Изучение темы, а затем её отработка это лучший способ запоминания. Спасибо большое за карточки для запоминания, очень удобно! А когда приложение присылает мне слова поддержки, это очень мотивирует, приятная опция! Хочу пожелать дальнейшего развития приложения",
        rating: 5,
        avatar: mariya
    },
    {
        name: "Владимир",
        grade: "11 класс",
        review: "Очень хорошее приложение! Приятное глазу, информация и проверка знаний перед глазами. Готовлюсь к испытательным экзаменам на балакавриат и прямо приятно, что можно не искать тесты во всем интернете, а всё сделать в одном приложении!",
        rating: 5,
        avatar: oleg
    },
    {
        name: "Арсений",
        grade: "11 класс",
        review: "Просто лучшие. Всё красиво, удобно, невероятно помогает в подготовке. Красавчики! Не жалко было заплатить за полную версию, оно того стоило ;)",
        rating: 5,
        avatar: oleg
    },
    {
        name: "Юлия",
        grade: "9 класс",
        review: "Доброе время суток! Приложение очень понравилось, полезный материал, красивый дизайн. Совсем не пожалела, купив его. Спасибо огромное разработчикам!",
        rating: 5,
        avatar: mariya
    },
    {
        name: "Артем",
        grade: "11 класс",
        review: "Весьма полезная штука, особенно для повторения или же фиксации самого важного. Приложение было незаменимым атрибутом на пути подготовки к ЕГЭ, спасибо разработчикам!",
        rating: 5,
        avatar: oleg
    },
    {
        name: "Алена",
        grade: "1 курс",
        review: "С ума сойти, шикарное приложение! Когда скачивала, не думала даже, что оно такое крутое. Спасибо большое, разработчики!",
        rating: 5,
        avatar: mariya
    },
    {
        name: "Кирилл",
        grade: "9 класс",
        review: "Приложение супер. Готовился к ОГЭ по бесплатной версии этого приложения и сдал его на пятёрку. Спасибо большое разработчикам за столь шикарное приложение, здоровья вам и всего наилучшего.",
        rating: 5,
        avatar: oleg
    },
    {
        name: "Елизавета",
        grade: "10 класс",
        review: "Чудесное приложение, купила премиум и не думаю, что пожалею! Спасибо, что делаете подготовку легче 🥰",
        rating: 5,
        avatar: mariya
    }
];
type SliderRef = Slider & {
    slickNext: () => void;
    slickPrev: () => void;
};


const ReviewCarousel = forwardRef<HTMLDivElement>((props, ref) => {
    console.log('ReviewCarousel rendered');

    let sliderRef = useRef<SliderRef | null>(null);
    const next = () => {
        sliderRef.current?.slickNext();
    };
    const prev = () => {
        sliderRef.current?.slickPrev();
    };

    const baseSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1
    }

    const [settings, setSettings] = useState({
        ...baseSettings,
        slidesToShow: 1,
    });

    const updateSettings = () => {
        if (window.innerWidth >= 1920) {
            setSettings({
                ...baseSettings,
                slidesToShow: 3
            });
        } else if (window.innerWidth >= 1280) {
            setSettings({
                ...baseSettings,
                slidesToShow: 3

            });
        } else if (window.innerWidth >= 768) {
            setSettings({
                ...baseSettings,
                slidesToShow: 2
            });
        } else {
            setSettings({
                ...baseSettings,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        }
    };

    useEffect(() => {
        updateSettings();
        window.addEventListener('resize', updateSettings);
        return () => window.removeEventListener('resize', updateSettings);
    }, []);

    return <div className={"reviews"} ref={ref}>
        <h2 className={"involve-500"}>Отзывы наших учеников</h2>
        <Slider {...settings}
                ref={slider => {
                    sliderRef.current = slider;
                }}>
            {reviews.map((review, index) => (
                <div key={index} className="review-card">
                    <div className="review-header">
                        <img src={review.avatar} alt={review.name} className="avatar"/>
                        <div className={"review-info"}>
                            <h3>{review.name}, {review.grade}</h3>
                            <div className="rating">
                                {Array.from({length: review.rating}).map((_, index) => (
                                    <img key={index} src={star} alt="star"/>
                                ))}
                            </div>
                        </div>
                    </div>
                    <p>{review.review}</p>
                </div>
            ))}
        </Slider>
        <div className={""}>
            <CustomSliderButton onClick={prev} className={"slick-arrow slick-prev"} type="prev"/>
            <CustomSliderButton onClick={next} className={"slick-arrow slick-next"} type="next"/>
        </div>
    </div>
});
export default ReviewCarousel;