import hex from "../../assets/images/hex.svg";
import LazyLoad from "react-lazyload";
import physics from "../../assets/images/physics.png";
import math from "../../assets/images/math.png";
import React, { forwardRef } from "react";

const Directions = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div className={"directions"} ref={ref}>
            <div className={"text-info"}>
                <div className={"title involve-500"}>
                    <span className={"main-text"}>Направления</span> подготовки
                </div>
                <div className={"description"}>
                    Запишитесь на бесплатную консультацию — мы ответим на любой вопрос и подскажем, как действовать, чтобы получить на ЕГЭ 80+ и поступить на бюджет
                </div>
            </div>
            <img src={hex} alt={"ЕГЭ"} className={"hex-decoration-right"} />
            <div className={"directions-list"}>
                <div className={"direction-card"}>
                    <div className={"direction-card-image-container"}>
                        <LazyLoad>
                            <img src={physics} className={"direction-card-image"} alt={"Физика"} />
                        </LazyLoad>
                    </div>
                    <div className={"direction-card-title"}>Физика</div>
                </div>
                <div className={"direction-card"}>
                    <LazyLoad>
                        <img src={math} className={"direction-card-image"} alt={"Математика"} />
                    </LazyLoad>
                    <div className={"direction-card-title"}>Математика</div>
                </div>
            </div>
        </div>
    );
});

export default Directions;