import Calendar from "./Calendar";
import '../../assets/styles/Account/Account.scss';
import GoalProgress from "./GoalProgress";
import {useEffect} from "react";
import {getAuthToken} from "../utils";

const Account: React.FC = () => {

    // useEffect(() => {
    //     if (getAuthToken() === null){
    //         window.location.href = "/log_in";
    //     }
    // });

    return(
        <div className={"account"}>
            <div className={"account-card"}><Calendar/></div>
            <div className={"account-card"} style={{width: "250px"}}><GoalProgress/></div>
        </div>
    );
}

export default Account;