import AuthLayout from "./AuthLayout";
import {Form} from "react-router-dom";
import React, {MouseEventHandler, ReactNode, useEffect, useState} from "react";
import {ReactComponent as VKlogo} from "../../assets/images/vk-color.svg";
import {LogInRequest} from "./api";
import notes from "../../assets/images/notes.png";
import * as VKID from '@vkid/sdk';
import { generateRandomString, writeLocal } from "../utils";

const LogInForm = () => {

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("Submitted");
        const success = await LogInRequest(e.currentTarget.username.value, e.currentTarget.password.value);
        if (success) {
            console.log("Success");
        } else {
            console.log("Error");
        }
    }
    return (
        <form className={"log-in-form"} onSubmit={handleSubmit}>
            <div className={"inputs"}>
                <input name="username" type="email" placeholder={"Электронная почта"} required={true}/>
                <input name="password" placeholder={"Пароль"} type={"password"} required={true}/>
            </div>
            <a href={"#"} className={"main-text forgot-password"}>Забыли пароль?</a>
            <button type={"submit"} className={"primary-button"}>Войти</button>
        </form>
    )
}

const RegisterForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [pwdError, setPwdError] = useState('');
    const [registerError, setRegisterError] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setRegisterError('');

        if (formData.password !== formData.confirmPassword) {
            setPwdError("Пароли не совпадают");
            return;
        }
        setPwdError('');

        const success = await LogInRequest(formData.email, formData.password, true);
        if (success) {
            console.log("Registration Success");
        } else {
            setRegisterError("Ошибка регистрации");
            console.log("Registration Error");
        }
    };

    return (
        <form className={"log-in-form"} onSubmit={handleSubmit}>
            <div className={"inputs"}>
                <input
                    name="email"
                    type="email"
                    placeholder={"Электронная почта"}
                    value={formData.email}
                    onChange={handleChange}
                    required={true}
                />
                <input
                    name="password"
                    type="password"
                    placeholder={"Пароль"}
                    value={formData.password}
                    onChange={handleChange}
                    required={true}
                    className={pwdError && 'error'}
                />
                <input
                    name="confirmPassword"
                    type="password"
                    placeholder={"Подтвердите пароль"}
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required={true}
                    className={pwdError && 'error'}
                />
            </div>
            {pwdError && <p className="error-text">{pwdError}</p>}
            <a href={"#"} className={"main-text forgot-password"}>Забыли пароль?</a>
            <button type={"submit"} className={"primary-button"}>Зарегистрироваться</button>
        </form>
    );
};

interface LogInProps {
    isLogin: boolean;
}

const AlternateLogIn = (props: LogInProps) => {
    const handleVkResponse = (data: any) => {
        console.warn(data)
    }

    const vkAuth = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        await VKID.Auth.login()
    }

    useEffect(() =>{
        const state = generateRandomString(30);
        writeLocal("vk-state", state);
        VKID.Config.init({
            app: 52258631,
            redirectUrl: 'https://mindon.etterellar.ru/vk-auth/',
            // redirectUrl: 'http://localhost',
            scope: 'email',
            state: state
        });
    }, [])


    return (
        <div className={"alternative-log-in"}>
            <div className="divider-with-text">
                <hr className="line"/>
                <span className="text">Или с помощью</span>
                <hr className="line"/>
            </div>
            <div className={"social-login"}>
                <a href={"#"} onClick={vkAuth}><VKlogo/></a>
            </div>
            <div className={"policies"}>
                При входе вы принимаете условия <a href={"#"} className={"main-text underlined"}>публичной
                оферты</a> и <a href={"#"}
                                className={"main-text underlined"}>политики обработки персональных данных</a>
            </div>
            <div className={"divider"}/>
            {props.isLogin ?
                <div className={"policies"}>
                    Нет аккаунта? <a href={"/sign_up"} className={"main-text"}>Зарегестрироваться</a>
                </div> : <div className={"policies"}>
                    Уже зарегистрированы? <a href={"/log_in"} className={"main-text"}>Войти</a>
                </div>}
        </div>
    );
}

interface Childrens {
    children: ReactNode;
}

const LoginRegisterCard: React.FC<Childrens> = ({children}) => {
    return (
        <div className={"auth-card"}>
            {children}
        </div>
    )
}
const LogIn = () => {
    return (
        <AuthLayout children={<LoginRegisterCard
            children={
                [<img src={notes} className={"notes"} key={"notes"}/>,
                    <div className={"title involve-700"} key={"title"}>Вход в Mind ON</div>,
                    <LogInForm key={"form"}/>,
                    <AlternateLogIn key={"alts"} isLogin={true}/>]
            }
        />
        }
        />
    )
}

export const Register = () => {
    return (
        <AuthLayout children={<LoginRegisterCard
            children={
                [<img src={notes} className={"notes"} key={"notes"}/>,
                    <div className={"title involve-700"} key={"title"}>Регистрация</div>,
                    <RegisterForm key={"form"}/>, <AlternateLogIn key={"alts"} isLogin={false}/>]}/>}/>
    )
}

export default LogIn;